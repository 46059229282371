<template>
  <div :style="{ 'direction': $vs.rtl ? 'rtl' : 'ltr' }">
    <feather-icon icon="CheckSquareIcon" svgClasses="h-5 w-5 hover:text-success cursor-pointer"
      @click="confirmApproveRecord" />
  </div>
</template>

<script>

import { mapActions } from 'vuex';
import loaderMixin from '../../../../../mixins/loaderMixin';

export default {
  mixins: [loaderMixin],
  name: 'CellRendererActions',
  methods: {
    ...mapActions('main_package', [
      'approvePackageRequestAction',
      'fetchAndSetPackageRequests'
    ]),
    confirmApproveRecord() {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: `Confirm Approve`,
        text: `You are about to approve record with id: "${this.params.data.id}"`,
        accept: this.approveRecord,
        acceptText: "Approve"
      })
    },
    approveRecord() {
      this.openLoading();
      this.approvePackageRequestAction(this.params.data.id).then(() => this.approvedSuccessfully());
    },
    approvedSuccessfully() {
      let params = new URLSearchParams();
      params.append("page", this.page);
      params.append("query[pending_request]", true);
      this.fetchAndSetPackageRequests({ query: params })
        .then(() => this.closeLoading()).catch((error) => this.closeLoading());

      this.$vs.notify({
        color: 'success',
        title: 'Package Approved',
        position: 'top-right',
        text: 'The selected package was successfully approved'
      })
    }
  }
}
</script>
