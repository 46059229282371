import { SEO_PAGEABLE_TYPE } from '../constant';
import {
  SET_SEO_PAGES,
  SET_SEO_PAGE_FORM,
  CLEAR_FORM,
  UPDATE_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  UPDATE_SEOPAGEABLE_ID_DROPDOWN
} from '../mutation-types';

export default {
  [SET_SEO_PAGES](state, records) {
    state.seo_pages.data = records.data;
    state.seo_pages.pagination.last_page = records.meta.last_page;
    state.seo_pages.pagination.per_page = records.meta.per_page;
    state.seo_pages.pagination.current_page = records.meta.current_page;
  },

  [UPDATE_SEOPAGEABLE_ID_DROPDOWN](state, records) {
    state.seopageable_type_dropdown = records;
  },
  [CLEAR_FORM](state) {
    state.form = {
      title: "",
      description: "",
      title_footer: "",
      description_footer: "",
      details_duration: "",
      details_cost: "",
      details_fav_time: "",
      details_permit: "",
      details_transport: "",
      itinerary: [
        {
          title: '',
          description: ''
        }
      ],
      cost_description: {
        title: '',
        description: '',
        on_budget: '',
        mid_range_budget: '',
        high_end_budget: '',
        permit_cost: '',
      },
      hero_text: "",
      seopageable_id: "",
      seopageable_type: {},
      banner_image: {
        file: '',
        alt: '',
        img_url: ''
      },
      hero_image: {
        file: '',
        alt: '',
        img_url: ''
      },
      gallery: [
        {
          id: '',
          file: '',
          alt: '',
          img_url: ''
        }
      ],
      nearby_regions: []
    };

    state.dropdown.type = []
  },

  [SET_SEO_PAGE_FORM](state, { records, seopageable }) {
    let formNearbyRegions = [];

    if (records.nearby_regions) {
      state.dropdown.nearby_regions = [];
  
      records.nearby_regions.forEach((region) => {
        const existingRegion = state.dropdown.nearby_regions.find(r => r.value === region.id);
        if (!existingRegion) {
          state.dropdown.nearby_regions.push({ label: region.display_name, value: region.id });
        }
        formNearbyRegions.push(region.id);
      });
    }

    state.form = {
      id: records.id,
      title: records.title,
      description: records.description,
      title_footer: records.title_footer,
      description_footer: records.description_footer,
      details_duration: records.details_duration,
      details_cost: records.details_cost,
      details_fav_time: records.details_fav_time,
      details_permit: records.details_permit,
      details_transport: records.details_transport,
      itinerary: records.itinerary,
      cost_description: records.cost_description,
      hero_text: records.hero_text,
      seopageable_id: records.seopageable_id,
      seopageable_type: records.seopageable_type,
      banner_image: {
        alt: records.banner_image[0].alt,
        file: '',
        img_url: records.banner_image[0].url
      },
      hero_image: {
        alt: records.hero_image[0].alt,
        file: '',
        img_url: records.hero_image[0].url
      },
      gallery: records.gallery.map(gall => ({ alt: gall.alt, file: '', img_url: gall.url, id: gall.id })),
      nearby_regions: formNearbyRegions
    };

    const seoPageableType = SEO_PAGEABLE_TYPE.find((type) => type.value == records.seopageable_type);

    state.dropdown.seopageable_type = seoPageableType;
    state.dropdown.seopageable_id = seopageable;
  },

  /**
   * Update Form.
   * 
   * @param {Object} state 
   * @param {*} param1 
   */
  [UPDATE_FORM](state, {
    key,
    value
  }) {
    if (key == "nearby_regions") {
      if (value.length === 0) {
        state.form[key] = [];
      } else {
        state.form[key] = value.map(region => region.value);
      }

      if (state.dropdown[key]) {
        state.dropdown[key] = value;
        return;
      }
    } else {
      if (state.dropdown[key]) {
        state.dropdown[key] = value;
        state.form[key] = value.value;
        return;
      }
      state.form[key] = value;
    }
  },

  /**
   * Set Search Parameters.
   * 
   * @param {Object} state 
   * @param {*} param1 
   */
  [SET_SEARCH_PARAMETERS](state, {
    key,
    value
  }) {
    state.search.query[key] = value;
  },

  /**
  * Clear Search Parameters.
  * 
  * @param {Object} state 
  * @param {*} param1 
  */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = '';
    state.search.query.name = '';
    state.search.query.display_name = '';
    state.search.query.type = '';
  },
};