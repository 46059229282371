
/**
 * Get list of hotels
 * 
 * @param {object} state 
 */
export const getHotels = (state) => {
    return state.hotels;
}

/**
 * Get form object
 * 
 * @param {object} state 
 */
export const  getFormObj = (state) => {
    return state.form;
}

/**
 * Get Country list
 * 
 * @param {object} state 
 */
export const getGeo = (state) => {
  return state.geo;
}

/**
 * Get Amenity list
 * 
 * @param {object} state 
 */
export const getAmenities = (state) => {
  return state.amenities;
}

/**
 * Get Images Lists
 * 
 * @param {object} state 
 */
export const getHotelImages = (state) => {
  return state.hotelImages;
}

/**
 * Get images for edit
 * 
 * @param {object} state 
 */
export const editHotelImages = (state) => {
  return state.editHotelImages;
}

export const previewImage = (state) => {
    return state.image_preview;
}

export const getSearchObj = (state) => {
    return state.search;
}

/**
 * Get list of Hotel Rooms
 * 
 * @param {object} state 
 */

export const getFromHotelRooms = (state) => {
  return state.rooms;
}


export const getHotelRoomImages = (state) => {
  return state.roomImages;
}

export const editHotelRoomImages = (state) => {
  return state.editRoomImages;
}

export const getRoomFormObj = (state) =>{
  return state.roomForm;
}

export const getRoomFormObjDropDown = (state) =>{
  return state.roomsMultipleData;
}

/**
 * Get Features list
 * 
 * @param {object} state 
 */
export const getFeatures = (state) => {
  return state.available_features;
}

export const getFormDropdownObj = (state) => {
  return state.form_dropdown;
}

export const getTags = (state) => {
  return state.tags;
}

/**
 * Get list of hotels requests
 * 
 * @param {object} state 
 */
export const getHotelRequests = (state) => {
  return state.hotelRequests;
}