<template>
    <div id="page-user-list">
        <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
          <!-- AgGrid Table -->
          <ag-grid-vue
            ref="agGridTable"
            :components="components"
            :gridOptions="gridOptions"
            class="ag-theme-material w-100 my-4 ag-grid-table"
            :columnDefs="columnDefs"
            :defaultColDef="defaultColDef"
            :rowData="hotelRequests.data"
            rowSelection="multiple"
            colResizeDefault="shift"
            :animateRows="true"
            :floatingFilter="true"
            :suppressPaginationPanel="true"
            :enableRtl="$vs.rtl"
            @filter-changed="onFilterChanged">
          </ag-grid-vue>
          <!-- End AgGrid Table -->
          <vs-pagination :total="hotelRequests.pagination.last_page" :value="hotelRequests.pagination.current_page" @input="paginate({page: $event})" />
        </div>
        </div>
    </template>
    
    
    <script>
    import { AgGridVue } from "ag-grid-vue"
    import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
    import vSelect from 'vue-select'
    
    
    // Cell Renderer
    import CellRendererLink from "../../page/cell-renderer/CellRendererLink.vue"
    import CellRendererStatus from "../../page/cell-renderer/CellRendererStatus.vue"
    import CellRendererVerified from "../../page/cell-renderer/CellRendererVerified.vue"
    import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
    import loaderDivMixin from '../../../../mixins/loaderDivMixin';
    import { mapGetters, mapActions } from 'vuex'
    export default {
      components: {
        AgGridVue,
        vSelect,
        CellRendererLink,
        CellRendererStatus,
        CellRendererVerified,
        CellRendererActions,
      },
      mixins: [loaderDivMixin],
      data() {
        return {
          page: 1,
          // Filter Options
          searchQuery: "",
    
          // AgGrid
          gridApi: null,
          gridOptions: {},
          defaultColDef: {
            sortable: true,
            resizable: true,
            suppressMenu: true
          },
          statusFilter: { label: 'All', value: 'all' },
          statusOptions: [
            { label: 'All', value: 'all' },
            { label: 'Active', value: 'active' },
            { label: 'Deactivated', value: 'deactivated' },
            { label: 'Blocked', value: 'blocked' },
          ],
          columnDefs: [
            {
              headerName: 'ID',
              field: 'id',
              width: 150,
              filter: true,
              checkboxSelection: true,
              headerCheckboxSelectionFilteredOnly: true,
              headerCheckboxSelection: true,
            },
            {
              headerName: 'Owner',
              field: 'owner.full_name',
              filter: true,
              width: 200,
            },
            {
              headerName: 'Email',
              field: 'email',
              filter: true,
              width: 210,
              cellRendererFramework: 'CellRendererLink'
            },
            {
              headerName: 'Name',
              field: 'name',
              filter: true,
              width: 225,
              filterParams: {
                clearButton: true,
                debounceMs: 200,
                newRowsAction: 'keep'
              }
            },
            {
              headerName: 'Rooms',
              field: 'rooms.length',
              filter: true,
              width: 150,
            },
            {
              headerName: 'Status',
              field: 'status',
              filter: true,
              width: 150,
              cellRendererFramework: 'CellRendererStatus'
            },
            {
              headerName: 'Actions',
              field: 'transactions',
              width: 150,
              cellRendererFramework: 'CellRendererActions',
            },
          ],
    
          // Cell Renderer Components
          components: {
            CellRendererLink,
            CellRendererStatus,
            CellRendererVerified,
            CellRendererActions,
          }
        }
      },
      computed: {
        ...mapGetters({
          hotelRequests: 'hotel/getHotelRequests',
          search: 'hotel/getSearchObj',
        }),
        searchParams(){
            let params = new URLSearchParams();
            params.append("page", this.page);
            params.append("query[pending_request]", true);
            (this.search.query.id != '') ? params.append("query[id]", this.search.query.id) : '';
            (this.search.query.name != '') ? params.append("query[name]", this.search.query.name) : '';
            (this.search.query.email != '') ? params.append("query[email]", this.search.query.email) : '';
    
            return params;
        }
      },
      methods: {
        ...mapActions('hotel', [
          'fetchAndSetHotelRequests',
          'setSearchParameters',
          'clearSearchParameters',
        ]),
        paginate(page){
          this.page=page.page;
          this.fetchHotelRequests();
        },
        onFilterChanged($event){
          this.setParams($event);
          this.fetchHotelRequests();
        },
        setParams($event){
          let $this = this;
          const searchKeys = Object.keys($event.api.getFilterModel());
          if(searchKeys.length == 0) this.clearSearchParameters();
          searchKeys.forEach(function(key, val){
            $this.setSearchParameters({key: key, value: $event.api.getFilterModel()[key].filter});
          });
        },
        fetchHotelRequests(){
          this.openLoading();
          this.fetchAndSetHotelRequests({query: this.searchParams})
              .then((res) => this.closeLoading())
              .catch((error) => this.closeLoading());
        }
      }
    }
    </script>
    
    <style lang="scss">
    #page-user-list {
      .user-list-filters {
        .vs__actions {
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-58%);
        }
      }
    }
    </style>
    