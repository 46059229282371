<template>
  <div id="page-user-list">

    <form method="POST" enctype="multipart/form-data" @submit.prevent="submitForm">
      <div class="vx-row mb-6">
        <div class="vx-col w-full md:w-2/3">
          <div class="vx-card p-6 mb-6">
            <SeoPageForm></SeoPageForm>
          </div>
          <div class="vx-card p-6 mb-6">
            <CostDescriptionForm></CostDescriptionForm>
          </div>
          <div class="vx-card p-6 mb-6">
            <ItineraryForm></ItineraryForm>
          </div>
        </div>

        <div class="vx-col w-full md:w-1/3">
          <div class="vx-card p-6 mb-6">
            <HeroImage :isEdit="true"></HeroImage>
          </div>
          <div class="vx-card p-6 mb-6">
            <GalleryImage :isEdit="true"></GalleryImage>
          </div>
          <div class="vx-card p-6 mb-6">
            <BannerImage :isEdit="true"></BannerImage>
          </div>
        </div>
      </div>

      <!-- Save & Reset Button -->
      <div class="vx-card p-4 mb-4">

        <div class="flex flex-wrap items-center justify-end">
          <vs-button button="submit" class="ml-auto">Save Changes</vs-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>

import SeoPageForm from './common/SeoPageForm';
import CostDescriptionForm from './common/CostDescriptionForm';
import ItineraryForm from './common/ItineraryForm';
import HeroImage from './common/HeroImage';
import GalleryImage from './common/GalleryImage';
import BannerImage from './common/BannerImage';
import loaderMixin from '../../../mixins/loaderMixin';
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    SeoPageForm,
    CostDescriptionForm,
    ItineraryForm,
    HeroImage,
    GalleryImage,
    BannerImage
  },
  mixins: [loaderMixin],
  computed: {
    ...mapGetters({
      form: 'seo_page/getFormObj',
    }),
    getSeoPageId() {
      return this.$route.params.seoPageId;
    },
  },
  created() {
    this.clearForm();
    this.clearValidationErrors();

    this.openLoading();
    this.fetchAndSetSeoPageById(this.getSeoPageId).then(() => {
      this.closeLoading();
    }).catch((r) => this.closeLoading());
  },
  methods: {
    ...mapActions({
      updateSeoPageAction: 'seo_page/updateSeoPageAction',
      fetchAndSetSeoPageById: 'seo_page/fetchAndSetSeoPageById',
      clearForm: 'seo_page/clearForm',
      clearValidationErrors: 'validation/clearValidationErrors',
    }),

    submitForm() {
      this.openLoading();

      let formData = new FormData();

      const fields = Object.keys(this.form);
      // formData.append('_method', 'PATCH');
      fields.forEach(field => {
        if (this.form[field] instanceof Array) {
          if (field === 'nearby_regions') {
            if(this.form[field].length > 0) {
              this.form[field].forEach((value, index) => {
                formData.append(`${field}[${index}]`, parseInt(value, 10));
              });
            } else {
              formData.append(`${field}`, []);
            }
          } else {
            this.form[field].forEach((data, key) => {
              const jsonDatas = Object.keys(data);
              jsonDatas.forEach(jsonData => {
                formData.append(`${field}[${key}][${jsonData}]`, data[jsonData]);
              })
            })
          }
        } else if (typeof this.form[field] === 'object' && this.form[field] !== null) {
          const objDatas = Object.keys(this.form[field]);

          objDatas.forEach(objData => {
            formData.append(`${field}[${objData}]`, this.form[field][objData]);
          })
        }
        else {
          formData.append(field, this.form[field]);
        }
      })

      this.updateSeoPageAction({
        seoPageId: this.getSeoPageId,
        data: formData
      }).then(async response => {
        if (response.status === 200) {
          this.closeLoading();
          this.$vs.notify({
            text: 'Seo page updated successfully.',
            title: 'Seo page Updated',
            color: 'success',
            position: 'top-right'
          });

          this.$router.push('/seo-pages');
        }
      }).catch(() => this.closeLoading());
    },
  },
}
</script>


<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
