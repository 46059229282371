import template from 'lodash/fp/template';
import {
    FETCH_PACKAGES,
    STORE_PACKAGE,
    FETCH_PACKAGE_DETAILS,
    UPDATE_PACKAGE,
    DELETE_PACKAGE,
    FETCH_VENDORS,
    FETCH_PACKAGE_REQUESTS,
    APPROVE_PACKAGE_REQUEST
} from './api';

import axios from 'axios';

/**
 * Fetch packages
 */
export const fetchPackages = (query) => axios.get(template(FETCH_PACKAGES)({ query }));

/**
 * Store package
 */
export const storePackage = (data) => axios.post(STORE_PACKAGE, data);

/**
 * Fetch package by id.
 *
 * @param {Integer} id
 */
export const fetchPackageById = (id) => axios.get(template(FETCH_PACKAGE_DETAILS)({ id }));

/**
 * Update package region.
 *
 * @param {*} id
 * @param {*} data
 */
export const updatePackage = (id, data) => axios.patch(template(UPDATE_PACKAGE)({ id }), data);

/**
 * Delete package region
 *
 * @param {Integer} id
 */
export const deletePackage = (id) => axios.delete(template(DELETE_PACKAGE)({ id }))

/**
 * Fetch List of vendors.
 */
export const fetchVendors = () => axios.get(FETCH_VENDORS);


/**
 * Fetch List of package requests.
 */
export const fetchPackageRequests = (query) => axios.get(template(FETCH_PACKAGE_REQUESTS)({ query }));

export const approvePackageRequest = (id) => axios.patch(template(APPROVE_PACKAGE_REQUEST)({ id }));