export default [
    {
        path: '/hotels',
        name: 'hotels',
        component: () => import('./page/List.vue')
    },
    {
      path: '/hotels/create',
      name: 'hotels.create',
      component: () => import('./page/Create.vue')
    },
    {
      path: '/hotels/:hotelId/edit',
      component: () => import('./page/Edit.vue'),
      name: 'hotels.edit',
    },
    {
      path: '/hotels/:hotelId/view',
      component: () => import('./page/View.vue'),
      name: 'hotels.view',
    },
    {
      path: '/hotels/:hotelId/rooms/create',
      component: () => import('./rooms/page/Create.vue'),
      name: 'hotels.rooms.create',
    },
    {
      path: '/hotels/:hotelId/rooms/:roomId/edit',
      component: () => import('./rooms/page/Edit.vue'),
      name: 'hotels.rooms.edit',
    },
    {
      path: '/requests/hotels',
      component: () => import('./requests/page/List.vue'),
      name: 'hotels.requests',
    }
]