/**
 * Get list of package regions
 *
 * @param {object} state
 */
export const getPackages = (state) => {
  return state.packages;
}


export const getFormObj = (state) => {
  return state.form;
}

export const getFormDropdownObj = (state) => {
  return state.form_dropdown;
}

export const getImages = (state) => {
  return state.package_images;
}

export const getImageUrl = (state) => {
  return state.image_url;
}

export const getSearchObj = (state) => {
  return state.search;
}

/**
 * Get list of Itenaries
 *
 * @param {object} state
 */

export const getFromPackageItenaries = (state) => {
  return state.itenaries;
}

export const getItenaryFormObj = (state) => {
  return state.itenaryForm;
}

export const getDepartureFormObj = (state) => {
  return state.departureForm;
}

export const getPackageDepartures = (state) => {
  return state.departures;
}


export const getGeo = (state) => {
  return state.geo;
}

/**
 * Get Features list
 * 
 * @param {object} state 
 */
export const getFeatures = (state) => {
  return state.available_features;
}

export const getTags = (state) => {
  return state.tags;
}

/**
 * Get list of package requests
 *
 * @param {object} state
 */
export const getPackageRequests = (state) => {
  return state.packageRequests;
}