<template>
    <div id="page-user-list">
        <div class="vx-card p-6 vs-con-loading__container" id="div-with-loading">
            <!-- AgGrid Table -->
            <ag-grid-vue ref="agGridTable" :components="components" class="ag-theme-material w-100 my-4 ag-grid-table"
                :columnDefs="columnDefs" :defaultColDef="defaultColDef" :rowData="homestayRequests.data"
                rowSelection="multiple" colResizeDefault="shift" :animateRows="true" :floatingFilter="true"
                :suppressPaginationPanel="true" :enableRtl="$vs.rtl" @filter-changed="onFilterChanged">
            </ag-grid-vue>
            <vs-pagination :total="homestayRequests.pagination.last_page"
                :value="homestayRequests.pagination.current_page" @input="paginate({ page: $event })" />
        </div>
    </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import vSelect from 'vue-select'


// Cell Renderer
import CellRendererActions from "./cell-renderer/CellRendererActions.vue"
import CellRendererBoolean from "../../page/cell-renderer/CellRendererBoolean.vue"
import loaderDivMixin from '../../../../mixins/loaderDivMixin';

import { mapGetters, mapActions } from 'vuex'

export default {
    components: {
        AgGridVue,
        vSelect,
        CellRendererActions,
        CellRendererBoolean
    },
    mixins: [loaderDivMixin],
    methods: {
        ...mapActions('homestay', [
            'fetchAndSetHomeStayRequests',
            'setSearchParameters',
            'clearSearchParameters',
        ]),
        paginate(page) {
            this.page = page.page;
            this.fetchHomestayRequests();
        },
        onFilterChanged($event) {
            this.setParams($event);
            this.fetchHomestayRequests();
        },
        setParams($event) {
            let $this = this;
            const searchKeys = Object.keys($event.api.getFilterModel());
            if (searchKeys.length == 0) this.clearSearchParameters();
            searchKeys.forEach(function (key, val) {
                $this.setSearchParameters({ key: key, value: $event.api.getFilterModel()[key].filter });
            });
        },
        fetchHomestayRequests() {
            this.openLoading();
            this.fetchAndSetHomeStayRequests({ query: this.searchParams })
                .then((res) => this.closeLoading())
                .catch((error) => this.closeLoading());
        }
    },

    computed: {
        ...mapGetters({
            homestayRequests: 'homestay/getHomestayRequests',
            search: 'homestay/getSearchObj',
        }),
        searchParams() {
            let params = new URLSearchParams();
            params.append("page", this.page);
            params.append("query[pending_request]", true);
            (this.search.query.id != '') ? params.append("query[id]", this.search.query.id) : '';
            (this.search.query.name != '') ? params.append("query[name]", this.search.query.name) : '';
            (this.search.query.email != '') ? params.append("query[email]", this.search.query.email) : '';

            return params;
        }
    },
    data() {
        return {
            page: 1,
            defaultColDef: {
                sortable: true,
                resizable: true,
                suppressMenu: true
            },
            columnDefs: [
                {
                    headerName: 'ID',
                    field: 'id',
                    width: 150,
                    filter: true,
                    filterParams: {
                        clearButton: true,
                        debounceMs: 200,
                        newRowsAction: 'keep'
                    }
                },
                {
                    headerName: 'Name',
                    field: 'name',
                    filter: true,
                    width: 250,
                    filterParams: {
                        clearButton: true,
                        debounceMs: 200,
                        newRowsAction: 'keep'
                    }
                },
                {
                    headerName: 'Email',
                    field: 'email',
                    filter: true,
                    width: 250,
                    filterParams: {
                        clearButton: true,
                        debounceMs: 200,
                        newRowsAction: 'keep'
                    }
                },
                {
                    headerName: 'Status',
                    field: 'status',
                    width: 150,
                    cellRendererFramework: 'CellRendererBoolean'
                },
                {
                    headerName: 'Actions',
                    field: 'transactions',
                    // width: 150,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],

            // Cell Renderer Components
            components: {
                CellRendererActions,
                CellRendererBoolean
            }
        }
    },
}
</script>

<style lang="scss">
#page-user-list {
    .user-list-filters {
        .vs__actions {
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-58%);
        }
    }
}
</style>