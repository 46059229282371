export const FETCH_PACKAGES  = '/v1/admin/package/packages/datatable?${query}';
export const STORE_PACKAGE  = '/v1/admin/package/packages';
export const FETCH_PACKAGE_DETAILS = '/v1/admin/package/packages/${id}';
export const UPDATE_PACKAGE = '/v1/admin/package/packages/${id}';
export const DELETE_PACKAGE = '/v1/admin/package/packages/${id}';
export const FETCH_VENDORS = '/v1/admin/vendors';
export const FETCH_PACKAGE_REQUESTS  = '/v1/admin/package/packages/datatable?${query}';
export const APPROVE_PACKAGE_REQUEST = '/v1/admin/package/packages/${id}/approve-request';


