
export const SET_HOMESTAYS = 'SET_HOMESTAYS';

export const CLEAR_FORM   = 'CLEAR_FORM';

export const SET_HOMESTAY_FORM = 'SET_HOMESTAY_FORM';

export const UPDATE_FORM = 'UPDATE_FORM';

export const SET_COUNTRIES = 'SET_COUNTRIES';

export const SET_AMENITIES = 'SET_AMENITIES';

export const SET_USERS = 'SET_USERS';

/* Homestay Rooms */
export const UPDATE_ROOM_FORM = 'UPDATE_ROOM_FORM';
export const CLEAR_ROOM_FORM   = 'CLEAR_ROOM_FORM';
export const SET_HOMESTAY_ROOM_FORM   = 'SET_HOMESTAY_ROOM_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';

export const SET_HOMESTAY_REQUESTS = 'SET_HOMESTAY_REQUESTS';