export const SET_HOTELS   = 'SET_HOTELS';
export const SET_HOTEL_FORM   = 'SET_HOTEL_FORM';
export const CLEAR_FORM   = 'CLEAR_FORM';
export const UPDATE_FORM   = 'UPDATE_FORM';

/* Hotel Rooms */
export const UPDATE_ROOM_FORM = 'UPDATE_ROOM_FORM';
export const CLEAR_ROOM_FORM   = 'CLEAR_ROOM_FORM';
export const SET_HOTEL_ROOM_FORM   = 'SET_HOTEL_ROOM_FORM';

export const SET_SEARCH_PARAMETERS = 'SET_SEARCH_PARAMETERS';

export const CLEAR_SEARCH_PARAMETERS = 'CLEAR_SEARCH_PARAMETERS';
export const SET_HOTEL_REQUESTS = 'SET_HOTEL_REQUESTS';