export default {
  hotels: {
    data: null,
    pagination: {
      last_page: 1,
      per_page: 10,
      current_page: 1,
    },
  },
  hotelRequests: {
    data: null,
    pagination: {
      last_page: 1,
      per_page: 10,
      current_page: 1,
    },
  },
  search: {
    query: {
      id: "",
      name: "",
      email: "",
    },
  },
  hotelImages: [
    {
      image: "",
      alt: "",
      source: [
        {
          source: null,
          options: {
            type: "",
            file: "",
          },
        },
      ],
    },
  ],
  image_preview: "",
  editHotelImages: [],
  geo: {
    hotel_region_id: [],
    user_id: [],
    amenities: [],
    images: [],
    available_features: [],
  },
  form: {
    hotelId: "",
    user_id: "",
    name: "",
    slug: "",
    email: "",
    website: "",
    default_price: "",
    is_full_payment: "",
    min_payment_percent: "",
    phone_1: "",
    phone_2: "",
    hotel_region_id: "",
    address_1: "",
    address_2: "",
    star_rating: "",
    description: "",
    features: "",
    is_sponsored: true,
    status: true,
    alt: [],
    images: [],
    amenities: [],
    meta_page_title: "",
    meta_keywords: "",
    meta_description: "",
    latitude: "",
    longitude: "",
    position: "",
    google_map: "",
    available_features: [],
    tag: "",
  },
  form_dropdown: {
    tag: {},
  },

  rooms: {
    data: [],
  },

  roomForm: {
    roomId: "",
    name: "",
    rooms_id: "",
    rooms_type: "",
    price: "",
    max_adult: "",
    max_child: "",
    beds: "",
    room_area: "",
    bathroom_area: "",
    extra_charge: "",
    status: "",
    description: "",
    alt: [],
    images: [],
    amenities: [],
  },
  roomImages: [
    {
      image: "",
      alt: "",
      source: [
        {
          source: null,
          options: {
            type: "",
            file: "",
          },
        },
      ],
    },
  ],
  editRoomImages: [],
  roomsMultipleData: {
    amenities: [],
    images: [],
  },

  tags: [
    { label: "Sponsored", value: "Sponsored" },
    { label: "Best Value", value: "Best Value" },
    { label: "Best Seller", value: "Best Seller" },
    { label: "Top Rated", value: "Top Rated" },
    { label: "Top Pick", value: "Top Pick" },
    { label: "Exclusive Offer", value: "Exclusive Offer" },
  ],
};
