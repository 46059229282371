import {
  SET_HOTELS,
  SET_HOTEL_FORM,
  UPDATE_FORM,
  CLEAR_FORM,
  UPDATE_ROOM_FORM,
  CLEAR_ROOM_FORM,
  SET_HOTEL_ROOM_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_HOTEL_REQUESTS,
} from "../mutation-types";

export default {
  [SET_HOTELS](state, records) {
    state.hotels.data = records.data;
    state.hotels.pagination.last_page = records.meta.last_page;
    state.hotels.pagination.per_page = records.meta.per_page;
    state.hotels.pagination.current_page = records.meta.current_page;
  },

  [SET_HOTEL_REQUESTS](state, records) {
    state.hotelRequests.data = records.data;
    state.hotelRequests.pagination.last_page = records.meta.last_page;
    state.hotelRequests.pagination.per_page = records.meta.per_page;
    state.hotelRequests.pagination.current_page = records.meta.current_page;
  },

  [CLEAR_FORM](state) {
    state.form.user_id = "";
    state.form.name = "";
    state.form.slug = "";
    state.form.email = "";
    state.form.website = "";
    state.form.default_price = "";
    state.form.is_full_payment = false;
    state.form.min_payment_percent = "";
    state.form.phone_1 = "";
    state.form.phone_2 = "";
    state.form.hotel_region_id = "";
    state.form.address_1 = "";
    state.form.address_2 = "";
    state.form.star_rating = "";
    state.form.description = "";
    state.form.features = "";
    state.form.is_sponsored = false;
    state.form.status = false;
    state.form.alt = [];
    state.form.amenities = [];
    state.form.images = [];
    state.form.meta_page_title = "";
    state.form.meta_keywords = "";
    state.form.meta_description = "";
    state.form.latitude = "";
    state.form.longitude = "";
    state.form.position = "";
    state.form.google_map = "";
    state.form.available_features = [];
    state.form.tag = "";

    state.form_dropdown = {
      tag: {},
    };

    state.hotelImages = [
      {
        image: "",
        alt: "",
        source: [
          {
            source: null,
            options: {
              type: "",
              file: "",
            },
          },
        ],
      },
    ];

    state.geo = {
      hotel_region_id: [],
      user_id: [],
      amenities: [],
      images: [],
      available_features: [],
    };
  },

  [SET_HOTEL_FORM](state, records) {
    let formAmenities = [];
    if (records.amenities) {
      records.amenities.forEach((am, index) => {
        state.geo.amenities.push({ label: am.display_name, value: am.id });
        formAmenities.push(am.id);
      });
    }

    let formFeatures = [];
    if (records.available_features) {
      records.available_features.forEach((am, index) => {
        state.geo.available_features.push({ label: am.title, value: am.id });
        formFeatures.push(am.id);
      });
    }
    if (records.images) {
      state.editHotelImages = [];
      records.images.forEach((img, index) => {
        state.editHotelImages.push({
          source: img.url,
          alt: img.alt,
          imageId: img.id,
        });
      });

      state.image_preview = records.images[0].url;
    }

    state.form.hotelId = records.id;
    state.form.user_id = records.owner.id;
    state.form.name = records.name;
    state.form.slug = records.slug;
    state.form.email = records.email;
    state.form.website = records.website;
    state.form.default_price = records.min_price;
    state.form.is_full_payment = records.full_payment;
    state.form.min_payment_percent = records.min_payment;
    state.form.phone_1 = records.phone_1;
    state.form.phone_2 = records.phone_2;
    state.form.hotel_region_id = records.region ? records.region.id : "";
    state.form.address_1 = records.address_1;
    state.form.address_2 = records.address_2;
    state.form.star_rating = records.rating;
    state.form.description = records.details;
    state.form.features = records.features;
    state.form.is_sponsored = records.is_sponsored;
    state.form.status = records.status;
    state.form.images = records.images;
    state.form.meta_page_title = records.meta_tag ? records.meta_tag.title : "";
    state.form.meta_keywords = records.meta_tag
      ? records.meta_tag.keywords
      : "";
    state.form.meta_description = records.meta_tag
      ? records.meta_tag.description
      : "";
    state.form.latitude = records.latitude;
    state.form.longitude = records.longitude;
    state.form.amenities = formAmenities;
    state.form.position = records.position;
    state.form.google_map = records.google_map;
    state.form.available_features = formFeatures;
    state.form.tag = records.tag;

    state.geo.hotel_region_id = {
      label: records.region ? records.region.name : "",
      value: records.region ? records.region.id : "",
    };
    state.geo.user_id = {
      label: records.owner.full_name,
      value: records.owner.id,
    };
    state.geo.images = records.images;

    state.rooms.data = records.rooms;

    state.form_dropdown = {
      tag: {
        label: records.tag,
        value: records.tag,
      },
    };
  },

  /**
   * Update Hotel Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_FORM](state, { key, value }) {
    if (state.form_dropdown[key]) {
      state.form_dropdown[key] = value;
      state.form[key] = value.value;
      return;
    }

    if (key == "amenities") {
      state.form[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.form[key].push(value[i].value);
      }

      if (state.geo[key]) {
        state.geo[key] = value;
        return;
      }
    } else if (key == "images") {
      if (state.form.images[value.index]) {
        state.form.images[value.index] = value.value;
      } else {
        state.form.images.push(value.value);
      }
      state.hotelImages[value.index].image = value.value;
    } else if (key == "alt") {
      if (state.form.alt[value.index]) {
        state.form.alt[value.index] = value.value;
      } else {
        state.form.alt.push(value.value);
      }
      state.hotelImages[value.index].alt = value.value;
    } else if (key.key == "addImage") {
      state.hotelImages.push(value);
    } else if (key.key == "RemoveImage") {
      state.hotelImages.splice(key.index, 1);
    } else if (key == "available_features") {
      state.form[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.form[key].push(value[i].value);
      }

      if (state.geo[key]) {
        state.geo[key] = value;
        return;
      }
    } else {
      if (state.geo[key]) {
        state.geo[key] = value;
        state.form[key] = value.value;
        return;
      }
      state.form[key] = value;
    }
  },
  /**
   * Set Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [SET_SEARCH_PARAMETERS](state, { key, value }) {
    state.search.query[key] = value;
  },
  /**
   * Clear Search Parameters.
   *
   * @param {Object} state
   * @param {*} param1
   */

  [CLEAR_SEARCH_PARAMETERS](state) {
    state.search.query.id = "";
    state.search.query.name = "";
    state.search.query.email = "";
  },

  /**
   * Update Homestay Rooms Form.
   *
   * @param {Object} state
   * @param {*} param1
   */
  [UPDATE_ROOM_FORM](state, { key, value }) {
    if (key == "amenities") {
      state.roomForm[key] = [];
      for (var i = 0; i < value.length; i++) {
        state.roomForm[key].push(value[i].value);
      }

      if (state.roomsMultipleData[key]) {
        state.roomsMultipleData[key] = value;
        return;
      }
    } else if (key == "images") {
      if (state.roomForm.images[value.index]) {
        state.roomForm.images[value.index] = value.value;
      } else {
        state.roomForm.images.push(value.value);
      }
      state.roomImages[value.index].image = value.value;
    } else if (key == "alt") {
      if (state.roomForm.alt[value.index]) {
        state.roomForm.alt[value.index] = value.value;
      } else {
        state.roomForm.alt.push(value.value);
      }
      state.roomImages[value.index].alt = value.value;
    } else if (key.key == "addImage") {
      state.roomImages.push(value);
    } else if (key.key == "RemoveImage") {
      state.roomImages.splice(key.index, 1);
    } else {
      if (state.roomsMultipleData[key]) {
        state.roomsMultipleData[key] = value;
        state.roomForm[key] = value.value;
        return;
      }
      state.roomForm[key] = value;
    }
  },

  [CLEAR_ROOM_FORM](state) {
    state.roomForm = {
      roomId: "",
      name: "",
      rooms_id: "",
      rooms_type: "",
      price: "",
      max_adult: "",
      max_child: "",
      beds: "",
      room_area: "",
      bathroom_area: "",
      extra_charge: "",
      status: "",
      description: "",
      alt: [],
      images: [],
      amenities: [],
    };

    state.roomsMultipleData = {
      amenities: [],
      images: [],
      alt: [],
    };

    state.roomImages = [
      {
        image: "",
        alt: "",
        source: [
          {
            source: null,
            options: {
              type: "",
              file: "",
            },
          },
        ],
      },
    ];
  },

  [SET_HOTEL_ROOM_FORM](state, records) {
    let setRoomAmenities = [];
    let roomAmenities = [];
    if (records.amenities) {
      records.amenities.forEach((am, index) => {
        setRoomAmenities.push({ label: am.display_name, value: am.id });
        roomAmenities.push(am.id);
      });
    }
    if (records.images) {
      state.editRoomImages = [];
      records.images.forEach((img, index) => {
        state.editRoomImages.push({
          source: img.url,
          alt: img.alt,
          imageId: img.id,
          featured: img.featured,
        });
      });
    }
    state.roomForm = {
      roomId: records.id,
      name: records.name,
      rooms_id: records.rooms_id,
      rooms_type: records.rooms_type,
      price: records.price,
      max_adult: records.max_adult,
      max_child: records.max_child,
      beds: records.beds,
      room_area: records.room_area,
      bathroom_area: records.bathroom_area,
      extra_charge: records.extra_charge,
      status: records.status,
      description: records.details,
      images: records.images,
      amenities: roomAmenities,
    };

    state.roomsMultipleData = {
      amenities: setRoomAmenities,
      images: setRoomAmenities,
    };
  },
};
