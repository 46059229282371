export default [
  {
    path: '/packages',
    name: 'packages',
    component: () => import('./page/List.vue')
  },
  {
    path: '/packages/create',
    name: 'packages.create',
    component: () => import('./page/Create.vue')
  },
  {
    path: '/packages/:packageId/edit',
    component: () => import('./page/Edit.vue'),
    name: 'packages.edit',
  },
  {
    path: '/packages/:packageId/view',
    component: () => import('./page/View.vue'),
    name: 'packages.view',
  },
  {
    path: '/packages/:packageId/edit-image',
    component: () => import('./page/EditImage.vue'),
    name: 'packages.edit_image',
  },
  {
    path: '/packages/:packageId/itenaries/create',
    component: () => import('./itenaries/page/Create.vue'),
    name: 'packages.itenaries.create',
  },
  {
    path: '/packages/:packageId/itenaries/:itenaryId/edit',
    component: () => import('./itenaries/page/Edit.vue'),
    name: 'packages.itenaries.edit',
  },
  {
    path: '/packages/:packageId/departures',
    component: () => import('./departures/page/List.vue'),
    name: 'packages.departures.list',
  },
  {
    path: '/packages/:packageId/departures/create',
    component: () => import('./departures/page/Create.vue'),
    name: 'packages.departures.create',
  },

  {
    path: '/requests/packages',
    component: () => import('./requests/page/List.vue'),
    name: 'packages.requests',
  },
];
