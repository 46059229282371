/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Booking manager admin
  Author: HopNepal
  Author URL: https://www.hopnepal.com
==========================================================================================*/


export default [
  {
    url: "/",
    name: "Home",
    slug: "home",
    icon: "HomeIcon",
  },
  {
    url: null,
    name: "Page Attribute",
    icon: "ApertureIcon",
    submenu: [{
      url: '/banners',
      name: "Banners",
      slug: "banners",
    }
    ]
  },
  {
    url: null,
    name: "CMS",
    icon: "BookOpenIcon",
    submenu: [{
      url: '/pages',
      name: "Pages",
      slug: "pages",
    }
    ]
  },
  {
    url: null,
    name: "Blogs",
    icon: "BookOpenIcon",
    submenu: [{
      url: '/categories',
      name: "Categories",
      slug: "categories",
    }, {
      url: '/blogs',
      name: "Blogs",
      slug: "blogs",
    }
    ]
  },
  {
    url: null,
    name: "Packages",
    icon: "PackageIcon",
    submenu: [
      {
        url: "/package-regions",
        name: "Package Region",
        slug: "package_region",
      },
      {
        url: "/package-activities",
        name: "Package Activities",
        slug: "package_activities",
      },
      {
        url: "/packages",
        name: "Packages",
        slug: "packages",
      },
    ]
  },
  {
    url: null,
    name: "Property",
    icon: "ShoppingCartIcon",
    submenu: [{
      url: '/amenities',
      name: "Amenities",
      slug: "amenities",
    },
    {
      url: '/features',
      name: "Features",
      slug: "features",
    },
    {
      url: "/hotel-regions",
      name: "Hotel Region",
      slug: "hotel_region",
    },
    {
      url: '/hotels',
      name: "Hotels",
      slug: "hotels",
    },
    {
      url: "/homestay-regions",
      name: "Homestay Region",
      slug: "homestay_region",
    },
    {
      url: '/homestays',
      name: "Homestays",
      slug: "homestays",
    },
    {
      url: null,
      name: "Requests",
      submenu: [
        {
          url: '/requests/hotels',
          name: "Hotels",
          slug: "hotels",
        },
        {
          url: "/requests/homestays",
          name: "Homestays",
          slug: "homestays",
        },
        {
          url: '/requests/packages',
          name: "Packages",
          slug: "packages",
        },
      ]
    }
    ]
  },
  {
    url: "/topdeals",
    name: "Top Deals",
    slug: "topdeals",
    icon: "AlertTriangleIcon",
  },
  {
    url: null,
    name: "System",
    icon: "PowerIcon",
    submenu: [{
      url: '/roles',
      name: "Roles",
      slug: "roles",
    }, {
      url: '/users',
      name: "Users",
      slug: "users",
    },]
  },
  {
    url: "/seo-pages",
    name: "Seo Pages",
    slug: "seo-pages",
    icon: "PackageIcon",
  },
  {
    url: "/bookings",
    name: "Bookings",
    slug: "Bookings",
    icon: "DollarSignIcon",
  },
  {
    url: "/notices",
    name: "Notices",
    slug: "Notices",
    icon: "AlertCircleIcon",
  },
  {
    url: "/settings",
    name: "Settings",
    slug: "settings",
    icon: "SettingsIcon",
  },
]
