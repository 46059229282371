import {
    fetchHomeStays,
    storeHomeStay,
    fetchHomeStayById,
    updateHomeStay,
    deleteHomeStay,
    fetchHomestayAmenities,
    fetchUsers,
    fetchCountries,
    deleteHomeStayImage,
    fetchHomestayRequests,
    approveHomestayRequest
} from '../service';

import {
    /* Homestay Room services */
    storeHomeStayRoom,
    deleteHomeStayRoom,
    fetchHomeStayRoomById,
    updateHomeStayRoom,
} from '../rooms/service';

import {
    SET_HOMESTAYS,
    CLEAR_FORM,
    SET_HOMESTAY_FORM,
    UPDATE_FORM,
    SET_AMENITIES,
    SET_USERS,
    SET_COUNTRIES,
    /* Homestay rooms */
    CLEAR_ROOM_FORM,
    UPDATE_ROOM_FORM,
    SET_HOMESTAY_ROOM_FORM,
    SET_SEARCH_PARAMETERS,
    CLEAR_SEARCH_PARAMETERS,
    SET_HOMESTAY_REQUESTS,
} from '../mutation-types';

/**
 * Get list of package regions
 * 
 * @param {*} param0 
 */
export const fetchAndSetHomeStays = ({ commit }, {query}) => {
    return fetchHomeStays(query)
        .then((response) => {
            commit(SET_HOMESTAYS, response.data)
            return Promise.resolve(response);
        });
}

export const createHomeStay = ({ commit }, data) => {
    return storeHomeStay(data)
        .then((response) => {
            if(response.status_code == 201){
                commit(CLEAR_FORM)
            }
            return Promise.resolve(response);
        });
}

export const fetchAndSetHomeStayById = ({ commit }, homestayId) => {
    return fetchHomeStayById(homestayId)
        .then((response) => {
            commit(SET_HOMESTAY_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updateHomeStayAction = ({ commit }, {homestayId, data}) => {
    return updateHomeStay(homestayId, data)
        .then((response) => {
            commit(CLEAR_FORM);
            return Promise.resolve(response);
        })
}

export const deleteHomeStayAction = ({ commit }, homestayId) => {
    return deleteHomeStay(homestayId)
        .then((response) => {
            return Promise.resolve(response);
        })
}

export const deleteHomeStayImageAction = ({ commit }, imageId) => {
    return deleteHomeStayImage(imageId)
        .then((response) => {
            return Promise.resolve(response);
        })
}


export const fetchAmenities = ({ commit }) => {
    return fetchHomestayAmenities()
        .then((response) => {
            commit(SET_AMENITIES, response.data.data);
            return Promise.resolve(response);
        })
}



/**
 * Fetch and set users.
 * 
 * @param {*} param0 
 */
export const fetchAndSetUsers = ({ commit }) => {
    return fetchUsers()
        .then((response) => {
            commit(SET_USERS, response)
            return Promise.resolve(response);
        });
}

/**
 * Fetch and set countries.
 * 
 * @param {*} param0 
 */
export const fetchAndSetCountries = ({ commit }) => {
    return fetchCountries()
        .then((response) => {
            commit(SET_COUNTRIES, response)
            return Promise.resolve(response);
        });
}

export const updateForm = ({commit}, data) => commit(UPDATE_FORM, data);

export const clearForm = ({commit}) => commit(CLEAR_FORM);

/**
 * Homestay Rooms Modules actions
 * 
 * @param {*} param0 
 */
export const updateRoomForm = ({commit}, data) => commit(UPDATE_ROOM_FORM, data);


export const clearRoomForm = ({commit}) => commit(CLEAR_ROOM_FORM);

export const createHomeStayRoom = ({ commit }, {homestayId, data}) => {
    return storeHomeStayRoom(homestayId, data)
        .then((response) => {
            if(response.status_code == 201){
                commit(CLEAR_ROOM_FORM)
            }
            return Promise.resolve(response);
        });
}

export const deleteHomeStayRoomAction = ({ commit }, {homestayId, roomId}) => {
    return deleteHomeStayRoom(homestayId, roomId)
        .then((response) => {
            return Promise.resolve(response);
        });
}

export const fetchAndSetHomeStayRoomById = ({ commit }, {homestayId, roomId}) => {
    return fetchHomeStayRoomById(homestayId, roomId)
        .then((response) => {
            commit(SET_HOMESTAY_ROOM_FORM, response.data.data);
            return Promise.resolve(response);
        })
}

export const updateHomeStayRoomAction = ({ commit }, {homestayId, roomId, data}) => {
    return updateHomeStayRoom(homestayId, roomId, data)
        .then((response) => {
            commit(CLEAR_ROOM_FORM);
            return Promise.resolve(response);
        })
}

export const setSearchParameters = ({
  commit
}, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
    commit
  }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);

  /**
 * Get list of homestay requests
 * 
 * @param {*} param0 
 */
export const fetchAndSetHomeStayRequests = ({ commit }, {query}) => {
    return fetchHomestayRequests(query)
        .then((response) => {
            commit(SET_HOMESTAY_REQUESTS, response.data)
            return Promise.resolve(response);
        });
}

export const approveHomestayRequestAction = ({ commit }, homestayId ) => {
    return approveHomestayRequest(homestayId)
        .then((response) => {
            return Promise.resolve(response);
        });
}