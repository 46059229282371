import {
  fetchPackages,
  storePackage,
  fetchPackageById,
  updatePackage,
  deletePackage,
  fetchVendors,
  fetchPackageRequests,
  approvePackageRequest
} from '../service';

import {
  /* Itenary services */
  storePackageItenary,
  deletePackageItenary,
  fetchPackageItenaryById,
  updatePackageItenary,
} from '../itenaries/service';

import {
  storePackageDeparture,
  fetchPackageDepartures,
  deletePackageDeparture,
  fetchPackageDepartureById
} from "../departures/service";

import {
  SET_PACKAGES,
  CLEAR_FORM,
  SET_PACKAGE_FORM,
  SET_VENDORS,
  UPDATE_FORM,
  /* Itenary */
  CLEAR_ITENARY_FORM,
  UPDATE_ITENARY_FORM,
  UPDATE_DEPARTURE_FORM,
  SET_PACKAGE_DEPARTURES,
  SET_PACKAGE_DEPARTURE_FORM,
  CLEAR_DEPARTURE_FORM,
  SET_PACKAGE_ITENARY_FORM,
  SET_SEARCH_PARAMETERS,
  CLEAR_SEARCH_PARAMETERS,
  SET_PACKAGE_REQUESTS
} from '../mutation-types';

/**
 * Get list of package regions
 *
 * @param {*} param0
 */
export const fetchAndSetPackages = ({commit}, {query}) => {
  return fetchPackages(query)
    .then((response) => {
      commit(SET_PACKAGES, response.data)
      return Promise.resolve(response);
    });
}

export const createPackage = ({commit}, data) => {
  return storePackage(data)
    .then((response) => {
      commit(CLEAR_FORM)
      return Promise.resolve(response);
    });
}

export const fetchAndSetPackageById = ({commit}, packageRegionId) => {
  return fetchPackageById(packageRegionId)
    .then((response) => {
      commit(SET_PACKAGE_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updatePackageAction = ({commit}, {packageId, data}) => {
  return updatePackage(packageId, data)
    .then((response) => {
      commit(CLEAR_FORM);
      return Promise.resolve(response);
    })
}

export const deletePackageAction = ({commit}, packageId) => {
  return deletePackage(packageId)
    .then((response) => {
      return Promise.resolve(response);
    })
}

/**
 * Fetch and set vendors.
 *
 * @param {*} param0
 */
export const fetchAndSetVendors = ({commit}) => {
  return fetchVendors()
    .then((response) => {
      commit(SET_VENDORS, response)
      return Promise.resolve(response);
    });
}

export const updateForm = ({commit}, data) => commit(UPDATE_FORM, data);

export const clearForm = ({commit}) => commit(CLEAR_FORM);

/**
 * Package Itenary Modules actions
 *
 * @param {*} param0
 */
export const updateItenaryForm = ({commit}, data) => commit(UPDATE_ITENARY_FORM, data);


export const clearItenaryForm = ({commit}) => commit(CLEAR_ITENARY_FORM);

export const updateDepartureForm = ({commit}, data) => commit(UPDATE_DEPARTURE_FORM, data);

export const clearDepartureForm = ({commit}) => commit(CLEAR_DEPARTURE_FORM);

export const createPackageItenary = ({commit}, {packageId, data}) => {
  return storePackageItenary(packageId, data)
    .then((response) => {
      if (response.status_code == 201) {
        commit(CLEAR_ITENARY_FORM)
      }
      return Promise.resolve(response);
    });
}

export const deletePackageItenaryAction = ({commit}, {packageId, itenaryId}) => {
  return deletePackageItenary(packageId, itenaryId)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export const fetchAndSetPackageItenaryById = ({commit}, {packageId, itenaryId}) => {
  return fetchPackageItenaryById(packageId, itenaryId)
    .then((response) => {
      commit(SET_PACKAGE_ITENARY_FORM, response.data.data);
      return Promise.resolve(response);
    })
}

export const updatePackageItenaryAction = ({commit}, {packageId, itenaryId, data}) => {
  return updatePackageItenary(packageId, itenaryId, data)
    .then((response) => {
      commit(CLEAR_ITENARY_FORM);
      return Promise.resolve(response);
    })
}

export const createPackageDeparture = ({commit}, {packageId, data}) => {
  return storePackageDeparture(packageId, data)
    .then((response) => {
      if (response.status_code == 201) {
        commit(CLEAR_DEPARTURE_FORM)
      }
      return Promise.resolve(response);
    });
}

export const fetchAndSetPackageDepartures = ({commit}, packageId) => {
  return fetchPackageDepartures(packageId)
    .then((response) => {
      commit(SET_PACKAGE_DEPARTURES, response.data);
      return Promise.resolve(response);
    })
}

export const deletePackageDepartureAction = ({commit}, {packageId, departureId}) => {
  return deletePackageDeparture(packageId, departureId)
    .then((response) => {
      return Promise.resolve(response);
    });
}

export const fetchAndSetPackageDepartureById = ({commit}, {packageId, departureId}) => {
  return fetchPackageDepartureById(packageId, departureId)
    .then((response) => {
      commit(SET_PACKAGE_DEPARTURE_FORM, response.data);
      return Promise.resolve(response);
    })
}

export const setSearchParameters = ({
                                      commit
                                    }, data) => commit(SET_SEARCH_PARAMETERS, data);

export const clearSearchParameters = ({
                                        commit
                                      }, data) => commit(CLEAR_SEARCH_PARAMETERS, data);

                                      
/**
 * Get list of package requests
 *
 * @param {*} param0
 */
export const fetchAndSetPackageRequests = ({commit}, {query}) => {
  return fetchPackageRequests(query)
    .then((response) => {
      commit(SET_PACKAGE_REQUESTS, response.data)
      return Promise.resolve(response);
    });
}

export const approvePackageRequestAction = ({ commit }, packageId ) => {
  return approvePackageRequest(packageId)
      .then((response) => {
          return Promise.resolve(response);
      });
}